import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import Home from '../components/Home';

const IndexPage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Home" />
        <Home />
    </Layout>
);

export default IndexPage
